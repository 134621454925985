import React, { Component } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import urlParser from '../utils/urlParser';

//Import Blocks
import TempHeader from '../blocks/expertiseHeaderTwo';
import HeaderThree from '../blocks/headerThree';
import FinePrintBlock from '../blocks/finePrintNew';
import FooterPreface from '../blocks/footerPreface';

//Import Components
import BlankSpace from '../components/blankSpace';
import Anchor from '../blocks/anchor';

export class FinePrintPage extends Component {
  render() {
    let data = this.props.data.allPrismicFinePrint.nodes[0].data;
    return (
      <Layout
        menu_background={'true'}
        header_background_color={'#212121'}
        logo={data.logo}
        header_items_color={'#fff'}
        mobile_logo={data.mobile_logo}
        mobile_header_items_color={'#ffffff'}
        fixed_background_transparent_mode={'false'}
        fixed_background_color={'#212121'}
        fixed_menu_items_color={'#ffffff'}
        fixed_menu_logo={data.fixed_logo}
        fixed_mobile_logo={data.fixed_mobile_logo}
        footer_logo={data.footer_logo}
      >
        <SEO
          title={data.seo_title[0].text}
          description={data.seo_description[0].text}
        />
        <HeaderThree
          header_text={data.header_title[0]}
          subtitle_text={data.header_subtitle_text[0]}
          color_theme={data.header_color_theme}
          background_color={data.header_background_color}
          header_text_color={data.header_title_color}
          subtitle_text_color={data.header_subtitle_color}
          right_image={data.header_right_image}
          left_image={data.header_left_image}
          cta_text={data.header_cta_text[0]}
          cta_link={data.header_cta_link}
          cta_text_color={data.header_cta_color}
          customFontSize={'60px'}
          nonLazyMode={false}
        />
        <Anchor anchor_id={'content'} />

        <FinePrintBlock
          legalSectionName={data.legal_title[0].text}
          legalSectionLastUpdated={data.legal_last_update_date[0].text}
          termsSectionName={data.terms_and_conditions_title[0].text}
          termsSectionLastUpdated={
            data.terms_and_conditions_last_updated_date[0].text
          }
          privacySectionName={data.privacy_policy_title[0].text}
          privacySectionLastUpdated={
            data.privacy_policy_last_updated_date[0].text
          }
          cookiesSectionName={data.cookies_title[0].text}
          cookieSectionLastUpdated={data.cookies_last_updated_date[0].text}
          cookiesSections={data.cookies_sections}
          privacySections={data.privacy_policy_sections}
          legalSections={data.legal_sections}
          termsSections={data.terms_and_conditions_sections}
          color_theme={data.block_color_theme}
        />
        <FooterPreface
          subtitle_text={data.preface_subtitle_text[0].text}
          main_text={data.preface_title_text[0].text}
          cta_text={data.preface_cta_text[0].text}
          cta_link={urlParser(data.preface_cta_link)}
          cta_color={data.preface_cta_color}
          footer_text_background_wave={data.preface_text_background_wave.url}
        />
      </Layout>
    );
  }
}

export default FinePrintPage;

export const query = graphql`
  query FinePrintQuery {
    allPrismicFinePrint {
      nodes {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          logo
          fixed_logo
          mobile_logo
          fixed_mobile_logo
          footer_logo
          block_color_theme
          page_name {
            type
            text
          }
          header_color_theme
          header_background_color
          header_title {
            text
          }
          header_title_color
          header_subtitle_text {
            text
          }
          header_subtitle_color
          header_cta_text {
            text
          }
          header_cta_link {
            url
          }
          header_cta_color
          header_left_image {
            url
          }
          header_right_image {
            url
          }
          preface_subtitle_text {
            text
          }
          preface_title_text {
            text
          }
          preface_cta_text {
            text
          }
          preface_cta_link {
            slug
          }
          preface_cta_color
          preface_text_background_wave {
            url
          }
          legal_title {
            type
            text
          }
          legal_last_update_date {
            type
            text
          }
          legal_sections {
            section_title {
              type
              text
            }
            section_paragraph {
              type
              text
            }
          }
          terms_and_conditions_title {
            text
          }
          terms_and_conditions_last_updated_date {
            text
          }
          terms_and_conditions_sections {
            section_title {
              text
            }
            section_paragraph {
              text
            }
          }
          privacy_policy_title {
            text
          }
          privacy_policy_last_updated_date {
            text
          }
          privacy_policy_sections {
            section_title {
              text
            }
            section_paragraph {
              text
            }
          }
          cookies_title {
            text
          }
          cookies_last_updated_date {
            text
          }
          cookies_sections {
            section_title {
              text
            }
            section_paragraph {
              text
            }
          }
        }
      }
    }
  }
`;
