import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import Legal from './legal';
import Cookies from './cookies';
import withLocation from '../../utils/withLocation';

import Privacy from './privacy';

const FinePrintContainer = styled.div``;

const TabHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(136, 136, 136, 0.1);
  height: 60px;
  padding-bottom: 5px;
`;

const TabItem = styled.div`
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 0px;
  border-color: #fff;
  border-bottom-width: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

const TabItemSelected = styled.div`
  cursor: pointer;
  text-shadow: 0 0 0.65px #333, 0 0 0.65px #333;
  border-style: solid;
  padding-left: 20px;
  padding-right: 20px;
  border-width: 0px;
  border-color: ${props => props.color};
  border-bottom-width: 5px;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

export class FinePrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'legal',
    };
  }

  componentDidMount = () => {
    const { location } = this.props;
    if (location.search !== null) {
      if (location.search === '?section=legal') {
        this.setState({
          activeTab: 'legal',
        });
      } else if (location.search === '?section=privacy') {
        this.setState({
          activeTab: 'privacy',
        });
      } else if (location.search === '?section=cookies') {
        this.setState({
          activeTab: 'cookies',
        });
      }
    }
  };

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  handleTabChange = tab => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <FinePrintContainer>
          <TabHeader>
            {this.state.activeTab === 'legal' ? (
              <TabItemSelected
                color={this.props.color_theme}
                onClick={() => this.handleTabChange('legal')}
              >
                {this.props.legalSectionName}
              </TabItemSelected>
            ) : (
              <TabItem onClick={() => this.handleTabChange('legal')}>
                {this.props.legalSectionName}
              </TabItem>
            )}
            {this.state.activeTab === 'privacy' ? (
              <TabItemSelected
                color={this.props.color_theme}
                onClick={() => this.handleTabChange('privacy')}
              >
                {this.props.privacySectionName}
              </TabItemSelected>
            ) : (
              <TabItem onClick={() => this.handleTabChange('privacy')}>
                {this.props.privacySectionName}
              </TabItem>
            )}
            {this.state.activeTab === 'cookies' ? (
              <TabItemSelected
                color={this.props.color_theme}
                onClick={() => this.handleTabChange('cookies')}
              >
                {this.props.cookiesSectionName}
              </TabItemSelected>
            ) : (
              <TabItem onClick={() => this.handleTabChange('cookies')}>
                {this.props.cookiesSectionName}
              </TabItem>
            )}
          </TabHeader>

          {this.state.activeTab === 'legal' ? (
            <Legal
              legalSectionName={this.props.legalSectionName}
              legalSectionLastUpdated={this.props.legalSectionLastUpdated}
              legalSections={this.props.legalSections}
              color_theme={this.props.color_theme}
            />
          ) : null}
          {this.state.activeTab === 'privacy' ? (
            <Privacy
              privacySectionName={this.props.privacySectionName}
              privacySectionLastUpdated={this.props.privacySectionLastUpdated}
              privacySections={this.props.privacySections}
              color_theme={this.props.color_theme}
            />
          ) : null}
          {this.state.activeTab === 'cookies' ? (
            <Cookies
              cookiesSectionName={this.props.cookiesSectionName}
              cookiesSections={this.props.cookiesSections}
              cookieSectionLastUpdated={this.props.cookieSectionLastUpdated}
              color_theme={this.props.color_theme}
            />
          ) : null}
        </FinePrintContainer>
      </VisibilitySensor>
    );
  }
}

export default withLocation(FinePrint);
